
import { defineComponent, ref, reactive, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import mAxiosApi from "@/api";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n()

    const state = reactive({
      loaderEnabled: true,
    });
    
    const returnBaseUrl = () => {
      return process.env.VUE_APP_BACK_URL+ "/connect";
    }

    let username = "";
    let password = "";
    
    store.dispatch(Actions.LOGOUT);

    window.localStorage.setItem('filterBordereau', "");
    window.localStorage.setItem('currentBord', "");
    window.localStorage.setItem('currentBordType', "");

    const submitButton = ref<HTMLElement | null>(null);

    const formLogin = ref<HTMLFormElement | null>(null);

    


    onMounted(async () => {
      
      state.loaderEnabled = false;

      if( router.currentRoute.value.params.accesstoken) {
        window.localStorage.setItem('accesstoken', router.currentRoute.value.params.accesstoken as any);
        window.localStorage.setItem('emailLinkedin', router.currentRoute.value.params.mail as any);
      }

    });



    // Faire ici un fonction qui vient vérifier si lors de l'ouverture de la page on a un accesstoken dans le localStorage
    // Si c'est le on appel loginAuto avec l'accesstoken et l'email

    // J'ai commencé à réaliser la fonction si dessous

    const loginWithAccesstoken = () => {
      const storedAccessToken = window.localStorage.getItem('accesstoken');
      const storedEmailLinkedin = window.localStorage.getItem('emailLinkedin');
      store.dispatch(Actions.LOGOUT);
      if (storedAccessToken) {
        loginAuto(storedEmailLinkedin, storedAccessToken);
      }
      else{
        window.location.href= returnBaseUrl();
      }
    }



    const loginAuto = (loginStr, password) => {
      const login = {username: loginStr, password: password};
      //console.log("Login", login);
      store.dispatch(Actions.LOGIN, login)
      .then(() => { 
        if (!store.getters.currentUser.uti_x_bloque){
          Swal.fire({
            title: t("Connexion établie !"), 
            text:
              t("Bienvenue") + " " +
              store.getters.currentUser.uti_c_prenom +
              " " +
              store.getters.currentUser.uti_c_nom +
              " " + t("sur votre espace Still'OnStock !"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("Accèder à Still'OnStock"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
          router.push({ name: "board" });
            })
        }
        else {
          Swal.fire({
          text: t("Votre compte est désactivé, veuillez contacter Stillnetwork afin de pouvoir vous reconnecter."),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("Retourner sur la page de connexion"),
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
          });
        }
    })
    .catch(() => {
        //console.log("Erreur Login");
        if(!router.currentRoute.value.params.mail) {
        window.location.href= returnBaseUrl();
        }

    });
        

  }

    if(router.currentRoute.value.params.mail) {
      store.dispatch(Actions.LOGOUT);
      loginAuto(router.currentRoute.value.params.mail,router.currentRoute.value.params.accesstoken);
    }

    //Create form validation object
    const login = Yup.object().shape({
      username: Yup.string()
        .email(t("L'identifiant doit être un email valide"))
        .required(t("L'identifiant est obligatoire"))
        .label("username"),
      password: Yup.string()
        .required(t("Le mot de passe est obligatoire"))
        .label("Password"),
    });



    //Form submit function
    const onSubmitLogin = async (values) => {

      store.dispatch(Actions.LOGOUT);
      
      // Clear existing errors
      //store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Dummy delay
      setTimeout(() => {
        //console.log(values);
        // Send login request
        store
          .dispatch(Actions.LOGIN, values)
          .then(() => { 
            
            if (!store.getters.currentUser.uti_x_bloque){
              Swal.fire({
                title: t("Connexion établie !"), 
                text:
                  t("Bienvenue") + " " +
                  store.getters.currentUser.uti_c_prenom +
                  " " +
                  store.getters.currentUser.uti_c_nom +
                  " " + t("sur votre espace Still'OnStock !"),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("Accèder à Still'OnStock"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                if (router.currentRoute.value.params.redirect) {
                  const stringRec = (router.currentRoute.value.params.redirect) as string;
                  router.push({ name: stringRec });
                } else {

                  store.dispatch(Actions.SET_CURRENT_BORD, {
                    bordSeq: 0,
                    bordType : ''
                  });

                  router.push({ name: "board" });
                }

              });

            } else {

                Swal.fire({
                text: t("Votre compte est désactivé, veuillez contacter Stillnetwork afin de pouvoir vous reconnecter."),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: t("Retourner sur la page de connexion"),
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });

            }


          })
          .catch(() => {
            Swal.fire({
              text: t("Il semble y avoir une erreur, veuillez vérifier vous identifiants de connexion."),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("Retourner sur la page de connexion"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 500);

    

    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      state,
      username,
      password,
      returnBaseUrl,
      loginWithAccesstoken
    };
  },
});
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}

function created() {
  throw new Error("Function not implemented.");
}
